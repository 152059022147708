import { ReactNode, useEffect, useMemo, useState } from "react";
import { createAppTheme } from "./createAppTheme";
import { ThemeProvider, useMediaQuery } from "@mui/material";
import useBreakpoint from "./useBreakpoint";
import { useColorMode } from "./ColorMode/useColorMode";

export function AppThemeProvider({
  children,
  createAppThemeFn,
}: {
  children: ReactNode;
  createAppThemeFn: typeof createAppTheme;
}) {
  const { colorMode } = useColorMode();
  const [spacing, setSpacing] = useState(8);

  const breakpoint = useBreakpoint();

  const appTheme = useMemo(
    () => createAppThemeFn({ mode: colorMode, breakpoint }),
    [colorMode, spacing],
  );

  const isSmallDevice = useMediaQuery(appTheme.breakpoints.down("md"));

  useEffect(() => {
    if (isSmallDevice) {
      setSpacing(2);
    } else {
      setSpacing(8);
    }
  }, [isSmallDevice]);

  return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
}
