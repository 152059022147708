import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactNode } from "react";
import { Grid } from "../layout/Grid";

export type TicketActionNavigationProps = {
  onNextActionClick?: () => void;
  onPrevActionClick?: () => void;
};

function ellipsis(str: string, length: number) {
  return str.length > length ? str.substring(0, length) + "..." : str;
}

export function DialogAppBar({
  onCloseClick,
  title,
  renderRight,
}: {
  onCloseClick: () => void;
  title: string;
  renderRight?: () => ReactNode;
}) {
  const theme = useTheme();
  const isMediumOrSmaller = theme.breakpoints.down("md");
  return (
    <AppBar sx={{ position: "relative" }}>
      <Toolbar sx={{ flex: 1 }}>
        <Grid container direction="row" alignItems="center" flex={1}>
          <Typography
            variant={isMediumOrSmaller ? "h6" : "h5"}
            ml={2}
            color={theme.palette.common.white}
            title={title}
          >
            {ellipsis(title, 50)}
          </Typography>

          <Grid
            container
            flex={1}
            justifyContent="flex-end"
            alignContent={"center"}
          >
            {renderRight?.()}
            <IconButton
              size={"large"}
              edge="start"
              color="inherit"
              onClick={onCloseClick}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
