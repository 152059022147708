import { logger } from "../../services/logger";
import { enqueueSnackbar } from "notistack";
import { gettext } from "../../services/i18n";

export async function copyToClipboard(content: object | string) {
  let clipboardContent = content;
  if (typeof clipboardContent === "object") {
    clipboardContent = JSON.stringify(clipboardContent, null, 2);
  }
  try {
    await navigator.clipboard.writeText(clipboardContent);
    enqueueSnackbar(gettext("Copied to clipboard"), { variant: "success" });
  } catch (err) {
    logger.error("Failed to copy JSON object: ", err);
  }
}
