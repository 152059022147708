import { useSnackbar } from "notistack";
import { gettext } from "./i18n";

export function useAppSnackbar() {
  const { enqueueSnackbar } = useSnackbar();

  function enqueueUnexpectedError() {
    enqueueSnackbar(gettext("Sorry, something unexpected has occurred"), {
      variant: "error",
    });
  }
  return {
    enqueueSnackbar,
    enqueueUnexpectedError,
  };
}
