import { forwardRef } from "react";
import {
  Link as RRRouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

export const RouterLink = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  return <RRRouterLink ref={ref} to={href} {...other} />;
});

RouterLink.displayName = "RouterLink";
