import { Breakpoint } from "@mui/material";

const breakpointsOrder: Breakpoint[] = ["xs", "sm", "md", "lg", "xl"];

/**
 * MUI doesn't always provide a way to specify a value by breakpoint. This function allows you to specify a value for a
 * specific breakpoint and have it fall back to the next smallest breakpoint if the value is not specified for the current breakpoint.
 *
 * This can be used in conjunction with the `useBreakpoint` hook to dynamically change styles based on the current breakpoint.
 */
const getValueForBreakpoint = <T>({
  breakpoint,
  breakpointValues,
}: {
  breakpoint: Breakpoint;
  breakpointValues: Partial<Record<Breakpoint, T>>;
}): T | undefined => {
  // Find the index of the current breakpoint in the order array
  const currentIndex = breakpointsOrder.indexOf(breakpoint);

  // Iterate backward from the current breakpoint to find the first match
  for (let i = currentIndex; i >= 0; i--) {
    const value = breakpointValues[breakpointsOrder[i]];
    if (value !== undefined) {
      return value;
    }
  }

  // Return undefined if no match is found
  return undefined;
};

export default getValueForBreakpoint;
