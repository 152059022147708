declare global {
  interface Window {
    gettext: (text: string) => string;
    react_app_context: {
      server_base_url: string;
      log_level: "info" | "warning" | "error" | "debug";
      team: {
        id: string;
        slug: string;
        name: string;
        team_member_email_addresses: string[];
      };
      user: {
        locale: string;
        user_id: string;
        is_anonymous: boolean;
        has_usable_password: boolean;
        is_superuser: boolean;
      };
    };
  }
}

const { server_base_url, user, log_level, team } =
  window.react_app_context || {};

export const appContext = {
  team,
  user,
  supportEmail: "support@capturedknowledge.ai",
  environment: import.meta.env.MODE,
  isProduction: false,
  logLevel: log_level?.toLowerCase() || "info",
  serverBaseUrl: server_base_url,
  muixLicenseKey:
    "ab6866d23fff9299169552940802b9f0Tz04NjAxNSxFPTE3NDE0NjMzNzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
};
