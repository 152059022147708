import Sentry from "./sentry";
import { appContext } from "./appContext";

type LogLevel = "error" | "info" | "warning" | "debug";

type LogExtras = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: Error | unknown | any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
};

interface LogContentWithLevel extends LogExtras {
  message: string;
  level: LogLevel;
}

export type GeneralLogger = {
  error: (message: string, logExtras?: LogExtras) => void;
  info: (message: string, logExtras?: LogExtras) => void;
  warn: (message: string, logExtras?: LogExtras) => void;
  debug: (message: string, logExtras?: LogExtras) => void;
};

const logLevelHierarchy = ["error", "warning", "info", "debug"];
const enabledLogMethods = logLevelHierarchy.slice(
  0,
  logLevelHierarchy.indexOf(appContext.logLevel) + 1,
);

function logToSentry({ message, data, level, error }: LogContentWithLevel) {
  Sentry.addBreadcrumb({ message, data, level });
  if (error) {
    Sentry.captureException(error);
  }
}

function logToConsole({ message, data, error, level }: LogContentWithLevel) {
  const logExtras = {} as LogExtras;
  let decoratedData = data;

  if (error) {
    logExtras.error = error;
  }
  if (error?.response) {
    decoratedData = {
      ...(decoratedData || {}),
      response: {
        data: error.response.data,
        status: error.response.status,
        url: error.response?.request?.responseURL,
      },
    };
  }
  if (decoratedData) logExtras.data = decoratedData;

  const consoleMethod = level.replace("warning", "warn") as
    | "error"
    | "info"
    | "warn"
    | "debug";
  // eslint-disable-next-line no-console
  console[consoleMethod](message, logExtras);
}

function logMethod({ message, data, error, level }: LogContentWithLevel): void {
  if (!enabledLogMethods.includes(level)) return;
  logToSentry({ message, data, error, level });
  logToConsole({ message, data, error, level });
}

function getLogger(): GeneralLogger {
  return {
    error: (message: string, { data, error }: LogExtras) =>
      logMethod({ message, data, error, level: "error" }),
    info: (message: string, { data, error }: LogExtras) =>
      logMethod({ message, data, error, level: "info" }),
    warn: (message: string, { data, error }: LogExtras) =>
      logMethod({ message, data, error, level: "warning" }),
    debug: (message: string, { data, error }: LogExtras) =>
      logMethod({ message, data, error, level: "debug" }),
  };
}

export const logger = getLogger();
