import * as Sentry from "@sentry/react";
import { appContext } from "./appContext";

Sentry.init({
  enabled: appContext.environment !== "development",
  environment: appContext.environment,
  dsn: "https://a1d22ef9fc935fb2129ba0b8e205e7bd@o4506815099633664.ingest.sentry.io/4506815104483328",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/capturedknowledge\.ai/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Sentry.setUser({ id: appContext?.user?.user_id });

export default Sentry;
