import { useContext } from "react";
import { ColorModeContext } from "./ColorModeContext";

export function useColorMode() {
  const colorModeContext = useContext<{
    colorMode: "light" | "dark";
    toggleColorMode: () => void;
  }>(ColorModeContext);

  return {
    colorMode: colorModeContext.colorMode,
    toggleColorMode: colorModeContext.toggleColorMode,
  };
}
