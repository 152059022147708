import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { ReactNode } from "react";
import { DialogAppBar, TicketActionNavigationProps } from "./DialogAppBar";

export function ResponsiveDrawer({
  open,
  onClose,
  children,
  title,
  renderDialogAppBarRight,
  fullscreenOverride,
}: {
  title: string;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  renderDialogAppBarRight?: () => ReactNode;
  fullscreenOverride?: boolean;
} & TicketActionNavigationProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (fullScreen || fullscreenOverride) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={fullScreen || fullscreenOverride}
      >
        <DialogAppBar
          onCloseClick={onClose}
          title={title}
          renderRight={renderDialogAppBarRight}
        />
        {children}
      </Dialog>
    );
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      SlideProps={{ style: { minWidth: 500 } }}
    >
      <DialogAppBar
        onCloseClick={onClose}
        title={title}
        renderRight={renderDialogAppBarRight}
      />
      {children}
    </Drawer>
  );
}
