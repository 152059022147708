import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Breakpoint } from "@mui/material";

// Custom hook to get the current MUI breakpoint as a string
function useBreakpoint(): Breakpoint {
  const theme = useTheme();

  // Define media queries for each breakpoint
  const breakpoints: Record<string, boolean> = {
    xs: useMediaQuery(theme.breakpoints.only("xs")),
    sm: useMediaQuery(theme.breakpoints.only("sm")),
    md: useMediaQuery(theme.breakpoints.only("md")),
    lg: useMediaQuery(theme.breakpoints.only("lg")),
    xl: useMediaQuery(theme.breakpoints.only("xl")),
  };

  // Determine the current breakpoint
  const breakpoint =
    Object.keys(breakpoints).find((key) => breakpoints[key]) || "xl";

  return breakpoint as Breakpoint;
}

export default useBreakpoint;
