import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ContentCopy } from "@mui/icons-material";
import { copyToClipboard } from "../../../nest-app/utils/copyToClipboard";
import { gettext } from "../../../services/i18n";

export function CopyInputAdornment({ value }: { value: string }) {
  return (
    <InputAdornment position="end">
      <IconButton
        onClick={() => copyToClipboard(value)}
        title={gettext("Copy")}
      >
        <ContentCopy />
      </IconButton>
    </InputAdornment>
  );
}
